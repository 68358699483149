<template>
  <div class="steps">
    <div
      v-if="storeData.type === 'company'"
      class=""
    >
    {{$tm('onboarding.kyc.borrowerTitle')}}

    </div>
    <span v-show="kycUploadType === 'upload' || files.length">
      <template v-if="storeKycType">
        <div class="steps__title">
          {{
            $tm('onboarding.kyc.identity_proof.title')
          }}
        </div>
        <div class="steps__subtitle">
          {{
            $tm(
              'onboarding.kyc.identity_proof.subtitle'
            )
          }}
        </div>
      </template>
      <div class="bg-white p-4 rounded-3xl">
        <file-upload
          ref="upload"
          v-model="files"
          accept="image/png,image/jpeg,image/jpg,application/pdf,application/xls,application/xlsx"
          :extensions="['jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx']"
          :size="1024 * 1024 * 7"
          @dragenter="detectDrag(true)"
          @dragleave="detectDrag(false)"
          input-id="file1"
          @mouseleave="detectDrag(false)"
          @dragover="moveCursor"
          post-action="/upload/post"
          :multiple="true"
          :maximum="storeData.type === 'company' ? 5 : 2"
          :thread="5"
          :drop="'.drop-area'"
          class="steps__upload relative w-full cursor-pointer"
          :class="{
            'opacity-50 cursor-not-allowed pointer-events-none':
              files.length > 5
          }"
        >
          <div
            class="drop-area w-full h-full z-50 absolute"
            @click.prevent="addFile(false)"
          ></div>
          <div
            class="
              absolute
              top-2/4
              left-2/4
              transform
              w-full
              -translate-y-1/2 -translate-x-1/2
            "
          >
            <div class="flex justify-center items-center">
              <div class="w-5 mr-3">
                <img src="@/assets/ico/onboarding/upload.svg" alt="" />
              </div>
              <div class="text-sm uppercase">
                {{ $tm('onboarding.kyc.upload.upload_files') }}
              </div>
            </div>
          </div>
          <div
            :style="cursorCircle"
            class="g-cursor__circle"
            :class="isDragging ? '' : 'opacity-0'"
          ></div>
        </file-upload>
        <div v-for="(file, index) in files" v-bind:key="file">
          <div
            class="py-2 px-4 flex justify-between font-bold mt-2"
            :class="
              validImg(file) ? 'steps__upload--success' : 'steps__upload--error'
            "
          >
            <div>{{ file.name }} - {{ formatSize(file.size) }}</div>
            <div
              class="w-4 mt-1 cursor-pointer"
              @click="files.splice(index, 1)"
            >
              <img src="@/assets/svg/garbage.svg" alt="" />
            </div>
          </div>
          <div class="card__card-input__error" style="margin-bottom: 0px">
            {{ errorMessage(file) }}
          </div>
        </div>
      </div>

      <div
        class="text-grey-700 opacity-75 mt-3 mb-5 text-13"
        v-if="storeKycType === 'IDENTITY_PROOF'"
      >
        {{ $tm('onboarding.kyc.upload.confirmation_message') }}
      </div>
      <Form
        class="form pb-28"
        ref="form"
        v-if="storeKycType === 'IDENTITY_PROOF'"
        v-slot="{ errors }"
      >
        <GeneralInput
          v-model:data="data.name"
          type="text"
          name="name"
          :label="$tm('onboarding.kyc.upload.fields.first_name')"
          rules="required|maxLength:20"
          :locked="true"
          :noNumbersOrSymbols="true"
          :error="errors.name"
        />
        <GeneralInput
          v-model:data="data.surname"
          type="text"
          name="surname"
          :label="$tm('onboarding.kyc.upload.fields.last_name')"
          rules="required|maxLength:30"
          :error="errors.surname"
          :locked="true"
          :noNumbersOrSymbols="true"
          no-focus
        />
      </Form>
    </span>
    <span v-show="kycUploadType === 'mobile' && files.length">
      <div class="steps__title">
        {{ $tm('onboarding.kyc.upload.mobile.title') }}
      </div>
      <div class="steps__subtitle">
        {{ $tm('onboarding.kyc.upload.mobile.subtitle') }}
      </div>
      <div class="bg-white text-center rounded-xl w-3/4 mx-auto">
        <kyc-generate
          @fileReceived="handleMobileUpload"
          @generatedId="emitId"
        />
      </div>
    </span>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import useRules from '@/assets/js/rules'
import FileUpload from 'vue-upload-component'
import KycGenerate from './KycGenerate.vue'
import { useI18n } from 'vue-i18n'
import GeneralInput from '@/components/general/Input.vue'

import { Form } from 'vee-validate'
import { useStore } from 'vuex'

export default {
  props: {
    kycType: {
      type: String,
      default: ''
    },
    kycUploadType: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    name: String,
    surname: String
  },
  components: {
    FileUpload,
    GeneralInput,
    Form,
    KycGenerate
  },
  emits: ['nextKyc', 'dataChanged', 'generatedId', 'canContinue'],
  setup(props, { emit }) {
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })

    let data = ref({ name: '', surname: '' })
    let upload = ref(null)
    let files = ref([])
    let xParent = ref(0)
    let yParent = ref(0)
    const validTypes = ref([
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
      'application/xls',
      'application/xlsx'
    ])
    let isDragging = ref(false)
    const form = ref(null)

    const { isRequired } = useRules()

    const cursorCircle = computed(() => {
      return `left: ${xParent.value}px ; top: ${yParent.value}px;`
    })

    const step = computed(() => {
      return store.state.onboarding.step
    })

    const storeData = computed(() => {
      return store.state.onboarding.data
    })

    const storeKycType = computed(() => {
      return store.state.onboarding.kyc_type
    })

    function errorMessage(file) {
      if (!validTypes.value.includes(file.type))
        return i18n.t('errors.invalid_type')
      else if (file.size > 1024 * 1024 * 7) return i18n.t('errors.big_file')
      else if (file.size < 1024 * 32) return i18n.t('errors.small_file')
    }

    function validImg(file) {
      if (file) {
        return (
          validTypes.value.includes(file.type) &&
          file.size < 1024 * 1024 * 7 &&
          file.size > 1024 * 32
        )
      }
      return false
    }

    function formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }
      return size.toString() + ' B'
    }

    function handleMobileUpload(e) {
      if (e.extra_fields) {
        if (files.value.length === 3) {
          files.value.shift()
        }
        files.value.push({
          file: e.image,
          size: e.image.size,
          name: e.extra_fields.name,
          type: e.extra_fields.type
        })
        tryEmit()
      }
    }

    function addFile() {
      document.getElementById('file1').click()
    }

    function moveCursor(e) {
      var proR = e.target.getBoundingClientRect()

      setTimeout(() => {
        xParent.value = e.clientX - proR.left - 35
        yParent.value = e.clientY - proR.top - 35
      }, 0)
    }

    function next(value) {
      emit('nextKyc', value)
    }

    function emitId(value) {
      emit('generatedId', value)
    }

    function detectDrag(value) {
      isDragging.value = value
    }

    async function tryEmit() {
      let validFiles = true

      if (!files.value.length) {
        validFiles = false
      }

      files.value.forEach((file) => {
        if (!validImg(file)) {
          validFiles = false
        }
      })

      if (validFiles) {        
        if (store.state.onboarding.kyc_type === 'IDENTITY_PROOF') {
          const validation = await form.value.validate()
          if (validation.valid){
            emit('canContinue', {
              kycFirst:  files.value[0] ? files.value[0].file : null,
              kycSecond: files.value[1] ? files.value[1].file : null
            })
          }
        }
        else{
          emit('canContinue', {
            kycFirst:  files.value[0] ? files.value[0].file : null,
            kycSecond: files.value[1] ? files.value[1].file : null,
            kycThird:  files.value[2] ? files.value[2].file : null,
            kycFourth: files.value[3] ? files.value[3].file : null,
            kycFifth:  files.value[4] ? files.value[4].file : null
          })
        }
      } else {
        emit('canContinue', false)
      }
    }

    watch(
      () => step.value,
      () => {
        if (step.value === 3) {
          data.value.name = storeData.value.name
          data.value.surname = storeData.value.surname
        }
      }
    )

    watch(
      () => props.isActive,
      async () => {
        data.value.name = props.name ?? storeData.value.name
        data.value.surname = props.surname ?? storeData.value.surname

        if (storeData.value.type !== 'particular') {
          if (props.isActive) {
            store.dispatch('setKycType', storeData.value.kyc_type)
          } else {
            store.dispatch('setKycType', null)
          }
        }
      }
    )

    watch(
      () => files.value,
      async () => {
        if (props.isActive) {
          detectDrag(false)
          tryEmit()
        }
      },
      { deep: true }
    )

    watch(data.value, async () => {
      if (props.isActive) {
        storeData.value.name = data.value.name
        storeData.value.surname = data.value.surname
        tryEmit()
      }
    })

    return {
      data,
      cursorCircle,
      files,
      storeKycType,
      emitId,
      addFile,
      storeData,
      form,
      detectDrag,
      formatSize,
      validImg,
      next,
      isRequired,
      errorMessage,
      moveCursor,
      handleMobileUpload,
      isDragging,
      upload
    }
  }
}
</script>
