<template>
  <div class="min-h-kyc relative">
    <div
      class="
        absolute
        left-1/2
        -translate-x-1/2 -translate-y-1/2
        transform
        top-1/2
      "
    >
      <qrcode-vue
        :value="serverbase + 'kyc/' + id"
        :size="100"
        level="H"
        v-if="id"
        class="mb-2 mx-auto"
      />
      <div class="text-xs">
        {{ serverbase + 'kyc/' + id }}
      </div>
      <div class="my-4 w-full text-center">
        <Loading center />
      </div>
      <div class="text-xs opacity-50">
        {{ $tm('onboarding.kyc.upload.mobile.do_not_leave') }}
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { ref, defineAsyncComponent } from 'vue'

export default {
  components: {
    QrcodeVue,
    Loading: defineAsyncComponent(() =>
      import('@/components/general/Loading.vue')
    )
  },
  data() {
    return {
      id: null
    }
  },
  emits: ['generatedId'],
  created() {
    // const socket = new WebSocket(process.env.VUE_APP_SERVER_WEBSOCKET)
    // Ao estabelecer a conexão enviamos uma mensagem pro servidor
    /*socket.addEventListener('open', () => {
      socket.send('Fetching KYC')
    })

    // Callback disparado sempre que o servidor retornar uma mensagem
    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      if (data[Object.keys(data)[0]] === 'Fetching KYC') {
        this.id = Object.keys(data)[0]
        this.$emit('generatedId', this.id)
      }
      if (data.msg) {
        var arrayBufferView = new Uint8Array(data.msg.data)
        var blob = new Blob([arrayBufferView], { type: data.extra_fields.type })
        // var urlCreator = window.URL || window.webkitURL
        // var imageUrl = urlCreator.createObjectURL(blob)
        // var img = document.querySelector('#photo')
        // img.src = imageUrl
        this.$emit('fileReceived', {
          image: blob,
          extra_fields: JSON.parse(data.extra_fields)
        })
      }
    })*/
  },
  setup() {
    const hostname = ref(location.host)
    const serverbase = ref(process.env.VUE_APP_FE_BASE_URL)
    return { hostname, serverbase }
  }
}
</script>
